import React from 'react'

function SliceMasters() {
	return (
		<div>
			<p>SliceMasters</p>
		</div>
	)
}

export default SliceMasters
